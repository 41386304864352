import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import JsonConverter from './components/JsonConverter';
import CsvConverter from './components/CsvConverter';
import Home from './components/Home';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>

                <Route path="/json/*" element={<Navigate replace to="/json/kotlin"/>}/>
                <Route path="/json/:language" element={<JsonConverter/>}/>

                <Route path="/csv/*" element={<Navigate replace to="/csv/json"/>}/>
                <Route path="/csv/:language" element={< CsvConverter/>}/>
            </Routes>
        </Router>
    );
};

export default App;