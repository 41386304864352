import React from 'react';
import { Link } from 'react-router-dom';

const BaseLayout = ({ children }) => {
    return (
        <>
            <nav className="bg-white">
                <div className="max-w-7xl mx-auto">
                    <div className="flex h-14">
                        <Link to="/" className="flex items-center gap-2 pl-2">
                            <img
                                src="/images/logo/logo-192x192.png"
                                alt="Kotlin Converter Logo"
                                className="h-8 w-8"
                            />
                            <span className="text-xl font-bold text-gray-800">Kotlin Converter</span>
                        </Link>
                    </div>
                </div>
            </nav>

            <div className="min-h-screen bg-gray-50">
                <div className="lg:hidden w-full">
                    <div className="bg-gray-50 mx-auto" style={{ minHeight: '100px', maxWidth: '320px' }}>
                        <div className="h-[100px] w-full flex items-center justify-center text-gray-400">
                            {/*Mobile Only Ad Space*/}
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="hidden lg:block w-[300px]">
                        <div className="bg-gray-50 sticky top-4">
                            <div className="h-[600px] w-full flex items-center justify-center text-gray-400">
                                {/*Left Ad Space*/}
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="flex-1 max-w-6xl mx-auto px-4 py-4">
                        {children}
                    </div>

                    <div className="hidden lg:block w-[300px]">
                        <div className="bg-gray-50 sticky top-4">
                            <div className="h-[600px] w-full flex items-center justify-center text-gray-400">
                                {/*Right Ad Space*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="bg-gray-50 mx-auto" style={{ minHeight: '90px', maxWidth: '728px' }}>
                        <div className="h-[90px] w-full flex items-center justify-center text-gray-400">
                            {/*Bottom Ad Space*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseLayout;