import React from 'react';

const LanguageSelector = ({ type, language, setLanguage }) => {
    const getOptions = () => {
        switch (type) {
            case 'json':
                return [
                    { value: 'kotlin', label: 'Kotlin' },
                    { value: 'java', label: 'Java' },
                    { value: 'typescript', label: 'TypeScript' },
                    { value: 'csv', label: 'CSV' }
                ];
            case 'csv':
                return [
                    { value: 'kotlin', label: 'Kotlin' },
                    { value: 'java', label: 'Java' },
                    { value: 'typescript', label: 'TypeScript' },
                    { value: 'json', label: 'JSON' }
                ];
            // 여기에 새로운 타입 추가 가능
            // case 'xml':
            //     return [...];
            default:
                console.warn(`Unsupported converter type: ${type}`);
                return [];
        }
    };

    const options = getOptions();

    return (
        <div className="flex-1">
            <label htmlFor="language-select" className="block text-sm font-medium text-gray-700">
                Select Language:
            </label>
            <select
                id="language-select"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default LanguageSelector;