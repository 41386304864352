function isDateString(value) {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}([ T])\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:\d{2})?$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return typeof value === 'string' && (dateTimeRegex.test(value) || dateRegex.test(value));
}

function getDateType(dateString) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        return 'LocalDate';
    } else if (/Z|[+-]\d{2}:\d{2}$/.test(dateString)) {
        return 'ZonedDateTime';
    } else {
        return 'LocalDateTime';
    }
}

export function analyzeJsonStructure(json) {
    const nullableFields = new Set();
    const dateTimeFields = new Map();

    function analyze(obj, path = '') {
        if (obj === null) {
            nullableFields.add(path);
        } else if (Array.isArray(obj)) {
            obj.forEach((item, index) => analyze(item, `${path}[${index}]`));

            // Check for nullable fields in array items
            if (obj.length > 0 && typeof obj[0] === 'object' && obj[0] !== null) {
                const allKeys = new Set(obj.flatMap(Object.keys));
                allKeys.forEach(key => {
                    if (obj.some(item => item[key] === null || item[key] === undefined)) {
                        nullableFields.add(`${path}[].${key}`);
                    }
                });
            }
        } else if (typeof obj === 'object') {
            Object.entries(obj).forEach(([key, value]) => {
                const newPath = path ? `${path}.${key}` : key;
                if (value === null || value === undefined) {
                    nullableFields.add(newPath);
                } else if (typeof value === 'string' && isDateString(value)) {
                    dateTimeFields.set(newPath, getDateType(value));
                }
                analyze(value, newPath);
            });
        }
    }

    analyze(json);
    return { nullableFields, dateTimeFields };
}

function convertFieldName(name, namingRule) {
    switch (namingRule) {
        case 'camelCase':
            return name.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        case 'snakeCase':
            return name.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
        case 'original':
        default:
            return name;
    }
}

function getKotlinType(value, fieldName, fieldPath, dateTimeFields, nullableFields) {
    let baseType;
    if (Array.isArray(value)) {
        const itemType = value.length > 0 ? getKotlinType(value[0], fieldName, `${fieldPath}[]`, dateTimeFields, nullableFields) : 'Any';
        baseType = `List<${itemType}>`;
    } else if (typeof value === 'object' && value !== null) {
        baseType = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    } else if (dateTimeFields.has(fieldPath)) {
        baseType = dateTimeFields.get(fieldPath);
    } else {
        switch (typeof value) {
            case 'string': baseType = 'String'; break;
            case 'number': baseType = Number.isInteger(value) ? 'Int' : 'Double'; break;
            case 'boolean': baseType = 'Boolean'; break;
            default: baseType = 'Any';
        }
    }
    return nullableFields.has(fieldPath) || nullableFields.has(`${fieldPath}[]`) ? `${baseType}?` : baseType;
}

export function jsonToKotlinClass(jsonData, className = 'Response', nullableFields, dateTimeFields, namingRule) {
    function convertObject(obj, objClassName, path = '') {
        let result = `data class ${objClassName}(\n`;
        const nestedClasses = [];

        Object.entries(obj).forEach(([key, value]) => {
            const fieldName = convertFieldName(key, namingRule);
            const fieldPath = path ? `${path}.${key}` : key;
            let fieldType = getKotlinType(value, fieldName, fieldPath, dateTimeFields, nullableFields);

            if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
                const itemClassName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}Item`;
                fieldType = `List<${itemClassName}>`;
                nestedClasses.push(convertObject(value[0], itemClassName, `${fieldPath}[]`));
            } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const nestedClassName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
                fieldType = nestedClassName;
                nestedClasses.push(convertObject(value, nestedClassName, fieldPath));
            }

            result += `    val ${fieldName}: ${fieldType},\n`;
        });

        result = result.slice(0, -2) + '\n)';
        return [result, ...nestedClasses].join('\n\n');
    }

    return convertObject(jsonData, className);
}

function getJavaType(value, fieldName, fieldPath, dateTimeFields, nullableFields) {
    let baseType;
    if (Array.isArray(value)) {
        const itemType = value.length > 0 ? getJavaType(value[0], fieldName, `${fieldPath}[]`, dateTimeFields, nullableFields) : 'Object';
        baseType = `List<${itemType}>`;
    } else if (typeof value === 'object' && value !== null) {
        baseType = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    } else if (dateTimeFields.has(fieldPath)) {
        baseType = dateTimeFields.get(fieldPath);
    } else {
        switch (typeof value) {
            case 'string': baseType = 'String'; break;
            case 'number': baseType = Number.isInteger(value) ? 'Integer' : 'Double'; break;
            case 'boolean': baseType = 'Boolean'; break;
            default: baseType = 'Object';
        }
    }
    return nullableFields.has(fieldPath) || nullableFields.has(`${fieldPath}[]`) ? `Optional<${baseType}>` : baseType;
}

export function jsonToJavaClass(jsonData, className = 'Response', nullableFields, dateTimeFields, namingRule) {
    function convertObject(obj, objClassName, path = '') {
        let result = `public class ${objClassName} {\n`;
        const nestedClasses = [];
        const methods = [];

        Object.entries(obj).forEach(([key, value]) => {
            const fieldName = convertFieldName(key, namingRule);
            const fieldPath = path ? `${path}.${key}` : key;
            let fieldType = getJavaType(value, fieldName, fieldPath, dateTimeFields, nullableFields);

            if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
                const itemClassName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}Item`;
                fieldType = `List<${itemClassName}>`;
                nestedClasses.push(convertObject(value[0], itemClassName, `${fieldPath}[]`));
            } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const nestedClassName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
                fieldType = nestedClassName;
                nestedClasses.push(convertObject(value, nestedClassName, fieldPath));
            }

            // Add field declaration
            result += `    private ${fieldType} ${fieldName};\n`;

            // Add getter method
            methods.push(`    public ${fieldType} get${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}() {\n        return ${fieldName};\n    }\n`);

            // Add setter method
            methods.push(`    public void set${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}(${fieldType} ${fieldName}) {\n        this.${fieldName} = ${fieldName};\n    }\n`);
        });

        result += '\n';
        result += methods.join('\n'); // Add all methods to the class
        result += '}\n';

        return [result, ...nestedClasses].join('\n\n');
    }

    return convertObject(jsonData, className);
}

function getTypeScriptType(value, fieldName, fieldPath, dateTimeFields, nullableFields) {
    let baseType;
    if (Array.isArray(value)) {
        const itemType = value.length > 0 ? getTypeScriptType(value[0], fieldName, `${fieldPath}[]`, dateTimeFields, nullableFields) : 'any';
        baseType = `${itemType}[]`;
    } else if (typeof value === 'object' && value !== null) {
        baseType = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    } else if (dateTimeFields.has(fieldPath)) {
        baseType = 'string'; // TypeScript uses string for all date types
    } else {
        switch (typeof value) {
            case 'string': baseType = 'string'; break;
            case 'number': baseType = 'number'; break;
            case 'boolean': baseType = 'boolean'; break;
            default: baseType = 'any';
        }
    }
    return nullableFields.has(fieldPath) || nullableFields.has(`${fieldPath}[]`) ? `${baseType} | null` : baseType;
}

export function jsonToTypeScript(jsonData, className = 'Response', nullableFields, dateTimeFields, namingRule) {
    function convertObject(obj, objClassName, path = '') {
        let result = `interface ${objClassName} {\n`;
        const nestedInterfaces = [];

        Object.entries(obj).forEach(([key, value]) => {
            const fieldName = convertFieldName(key, namingRule);
            const fieldPath = path ? `${path}.${key}` : key;
            let fieldType = getTypeScriptType(value, fieldName, fieldPath, dateTimeFields, nullableFields);

            if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
                const itemInterfaceName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}Item`;
                fieldType = `${itemInterfaceName}[]`;
                nestedInterfaces.push(convertObject(value[0], itemInterfaceName, `${fieldPath}[]`));
            } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const nestedInterfaceName = `${objClassName}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
                fieldType = nestedInterfaceName;
                nestedInterfaces.push(convertObject(value, nestedInterfaceName, fieldPath));
            }

            result += `  ${fieldName}: ${fieldType};\n`;
        });

        result += '}\n';
        return [result, ...nestedInterfaces].join('\n\n');
    }

    return convertObject(jsonData, className);
}

export function jsonToCsv(jsonData) {
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
        throw new Error("Invalid JSON data. Expected a non-empty array of objects.");
    }

    // Helper function to flatten JSON data
    function flattenObject(obj, parentKey = '', result = {}) {
        Object.entries(obj).forEach(([key, value]) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                flattenObject(value, fullKey, result);
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    flattenObject(item, `${fullKey}[${index}]`, result);
                });
            } else {
                result[fullKey] = value;
            }
        });
        return result;
    }

    // Flatten each object and collect all keys for CSV headers
    const flattenedData = jsonData.map(item => flattenObject(item));
    const headers = Array.from(
        flattenedData.reduce((keys, item) => {
            Object.keys(item).forEach(key => keys.add(key));
            return keys;
        }, new Set())
    );

    // Generate CSV
    const csvRows = [
        headers.join(','), // Header row
        ...flattenedData.map(item =>
            headers.map(header => {
                const value = item[header];
                return typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value ?? ''; // Handle commas, quotes, and null values
            }).join(',')
        )
    ];

    return csvRows.join('\n');
}


// conversionUtils.js에 추가
function inferValue(value) {
    if (value === '') return '';
    if (!isNaN(value)) {
        const num = Number(value);
        return num;
    }
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;

    if (isDateString(value)) { // 이미 있는 isDateString 활용
        return value;
    }

    return value;
}

export function parseCsv(csvText) {
    try {
        const lines = csvText.trim().split('\n');
        if (lines.length === 0) throw new Error('CSV is empty');

        const headers = lines[0].split(',').map(h => h.trim());

        const data = lines.slice(1).map(line => {
            const values = line.split(',').map(v => v.trim());
            return headers.reduce((obj, header, index) => {
                obj[header] = inferValue(values[index]);
                return obj;
            }, {});
        });

        return data;
    } catch (err) {
        throw new Error('Invalid CSV format');
    }
}

export function csvToJson(csvText) {
    const data = parseCsv(csvText);
    return JSON.stringify(data, null, 2);
}

export function csvToKotlinClass(csvText, className, namingRule) {
    try {
        // 1단계: CSV -> JSON 데이터로 변환
        const jsonData = parseCsv(csvText);

        // 2단계: 기존의 JSON -> Kotlin 변환 사용
        const { nullableFields, dateTimeFields } = analyzeJsonStructure(jsonData);
        const result = jsonToKotlinClass(
            jsonData[0], // 첫 번째 객체를 기준으로 클래스 생성
            className,
            nullableFields,
            dateTimeFields,
            namingRule
        );
        return result;
    } catch (err) {
        throw new Error('Failed to convert CSV to Kotlin: ' + err.message);
    }
}

export function csvToJavaClass(csvText, className, namingRule) {
    try {
        // 1단계: CSV -> JSON 데이터로 변환
        const jsonData = parseCsv(csvText);

        // 2단계: 기존의 JSON -> Java 변환 사용
        const { nullableFields, dateTimeFields } = analyzeJsonStructure(jsonData);
        const result = jsonToJavaClass(
            jsonData[0], // 첫 번째 객체를 기준으로 클래스 생성
            className,
            nullableFields,
            dateTimeFields,
            namingRule
        );
        return result;
    } catch (err) {
        throw new Error('Failed to convert CSV to Java: ' + err.message);
    }
}

export function csvToTypeScript(csvText, className, namingRule) {
    try {
        // 1단계: CSV -> JSON 데이터로 변환
        const jsonData = parseCsv(csvText);

        // 2단계: 기존의 JSON -> TypeScript 변환 사용
        const { nullableFields, dateTimeFields } = analyzeJsonStructure(jsonData);
        const result = jsonToTypeScript(
            jsonData[0], // 첫 번째 객체를 기준으로 클래스 생성
            className,
            nullableFields,
            dateTimeFields,
            namingRule
        );
        return result;
    } catch (err) {
        throw new Error('Failed to convert CSV to TypeScript: ' + err.message);
    }
}