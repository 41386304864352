import React from 'react';
import BaseLayout from './BaseLayout';

const ConverterLayout = ({ title, children }) => {
    return (
        <BaseLayout>
            <div>
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
                    {title}
                </h1>
                {children}
            </div>
        </BaseLayout>
    );
};

export default ConverterLayout;