import React from 'react';

const NamingRuleSelector = ({ namingRule, setNamingRule }) => {
    return (
        <div className="flex-1">
            <label htmlFor="naming-rule-select" className="block text-sm font-medium text-gray-700">
                Naming Rule:
            </label>
            <select
                id="naming-rule-select"
                value={namingRule}
                onChange={(e) => setNamingRule(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
                <option value="camelCase">camelCase</option>
                <option value="snakeCase">snake_case</option>
                <option value="original">Original</option>
            </select>
        </div>
    );
};

export default NamingRuleSelector;