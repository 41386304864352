import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    const converters = [
        {
            title: 'JSON Converter',
            description: 'Convert JSON to CSV, Kotlin, Java, or TypeScript classes with proper type inference and nullability handling.',
            options: [
                { name: 'Kotlin', path: '/json/kotlin', desc: 'Generate Kotlin data classes' },
                { name: 'Java', path: '/json/java', desc: 'Generate Java classes with getters/setters' },
                { name: 'TypeScript', path: '/json/typescript', desc: 'Generate TypeScript interfaces' },
                { name: 'CSV', path: '/json/csv', desc: 'Convert JSON to CSV format' },
            ],
            bgColor: 'bg-blue-50',
            iconColor: 'text-blue-500',
            hoverColor: 'hover:bg-blue-100'
        },
        {
            title: 'CSV Converter',
            description: 'Convert CSV to JSON, Kotlin, Java, or TypeScript with automatic type detection.',
            options: [
                { name: 'Kotlin', path: '/csv/kotlin', desc: 'Generate Kotlin data classes' },
                { name: 'Java', path: '/csv/java', desc: 'Generate Java classes with getters/setters' },
                { name: 'TypeScript', path: '/csv/typescript', desc: 'Generate TypeScript interfaces' },
                { name: 'JSON', path: '/csv/json', desc: 'Convert CSV to JSON format' },
            ],
            bgColor: 'bg-green-50',
            iconColor: 'text-green-500',
            hoverColor: 'hover:bg-green-100'
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            {/* Header */}
            <div className="max-w-7xl mx-auto text-center mb-12">
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                    Free Kotlin Converter
                </h1>
                <p className="text-xl text-gray-600">
                    Quick and simple data format conversion tools for developers
                </p>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto">
                <div className="grid gap-8 md:grid-cols-2">
                    {converters.map((converter) => (
                        <div key={converter.title} className={`${converter.bgColor} p-6 rounded-lg shadow-sm`}>
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">{converter.title}</h2>
                            <p className="text-gray-600 mb-6">{converter.description}</p>

                            <div className="grid gap-4">
                                {converter.options.map((option) => (
                                    <Link
                                        key={option.path}
                                        to={option.path}
                                        className={`p-4 rounded-lg border border-gray-200 ${converter.hoverColor} transition-colors duration-200`}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <h3 className="text-lg font-semibold text-gray-900">{option.name}</h3>
                                                <p className="text-sm text-gray-600">{option.desc}</p>
                                            </div>
                                            <svg
                                                className={`w-6 h-6 ${converter.iconColor}`}
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Features Section */}
                <div className="mt-16 grid gap-8 md:grid-cols-3">
                    <div className="bg-white p-6 rounded-lg shadow-sm">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">Automatic Type Detection</h3>
                        <p className="text-gray-600">Intelligently detects data types including dates and nullable fields.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-sm">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">Naming Conventions</h3>
                        <p className="text-gray-600">Support for camelCase, snake_case, and original naming conventions.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-sm">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">Easy to Use</h3>
                        <p className="text-gray-600">Simple, intuitive interface with instant conversion results.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;