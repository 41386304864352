import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ConverterLayout from "./layout/ConverterLayout";
import LanguageSelector from './LanguageSelector';
import NamingRuleSelector from './NamingRuleSelector';
import {
    csvToJson,
    csvToKotlinClass,
    csvToJavaClass,
    csvToTypeScript
} from '../utils/conversionUtils';

const CsvConverter = () => {
    const { language } = useParams();
    const navigate = useNavigate();
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const [fieldNameStyle, setFieldNameStyle] = useState('camelCase');
    const [error, setError] = useState('');

    const example = `name,age,email,joinDate,isActive
John Doe,30,john@example.com,2024-01-01,true
Jane Smith,25,jane@example.com,2024-01-02,false
Bob Wilson,35,bob@example.com,2024-01-03,true`;

    useEffect(() => {
        if (!['json', 'kotlin', 'java', 'typescript'].includes(language)) {
            navigate('/csv/json', { replace: true });
        }
    }, [language, navigate]);

    useEffect(() => {
        if (input) {
            handleConvert();
        }
    }, [language, fieldNameStyle]);

    const handleLanguageChange = (newLanguage) => {
        navigate(`/csv/${newLanguage}`);
    };

    const handleConvert = useCallback(() => {
        try {
            const valueToConvert = input.trim() || example;
            if (!input.trim()) {
                setInput(valueToConvert);  // 비어있을 때만 예제로 input 업데이트
            }

            let result;
            switch (language) {
                case 'json':
                    result = csvToJson(valueToConvert);
                    break;
                case 'kotlin':
                    result = csvToKotlinClass(valueToConvert, 'CsvData', fieldNameStyle);
                    break;
                case 'java':
                    result = csvToJavaClass(valueToConvert, 'CsvData', fieldNameStyle);
                    break;
                case 'typescript':
                    result = csvToTypeScript(valueToConvert, 'CsvData', fieldNameStyle);
                    break;
                default:
                    throw new Error('Unsupported language');
            }
            setOutput(result);
            setError('');
        } catch (err) {
            setError('Error: ' + err.message);
            setOutput('');
        }
    }, [input, language, fieldNameStyle]);

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(output).then(() => {
            console.log('Output copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }, [output]);

    return (
        <ConverterLayout title="CSV Converter">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <LanguageSelector type="csv" language={language} setLanguage={handleLanguageChange} />
                <NamingRuleSelector namingRule={fieldNameStyle} setNamingRule={setFieldNameStyle} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="space-y-3">
                    <textarea
                        className="w-full h-[calc(66vh-200px)] min-h-[300px] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={example}
                    />
                    <button
                        className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                        onClick={handleConvert}
                    >
                        Convert
                    </button>
                </div>
                <div className="space-y-3">
                    <pre className="w-full h-[calc(66vh-200px)] min-h-[300px] p-3 bg-gray-100 border border-gray-300 rounded-md overflow-auto">
                        {output}
                    </pre>
                    <button
                        className="w-full py-2 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                        onClick={handleCopy}
                    >
                        Copy Output
                    </button>
                </div>
            </div>
            {error && <div className="mt-4 text-red-500 font-semibold text-center">{error}</div>}
        </ConverterLayout>
    );
};

export default CsvConverter;